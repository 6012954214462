import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/budowanie-relacji-tlo.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    relacje: file(relativePath: { eq: "zarzadzanie-relacjami-crm.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
        zdjecie2: file(relativePath: { eq: "dynamika-satysfakcji-utrzymanie-wspolpracy-z-klientami-min.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Specrm = ({ data }) => {
  return (
    <Artykul
      title="5 dobrych praktyk budowania relacji z&nbsp;klientami"
      keywords={["budowania relacji z klientami"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="5 dobrych praktyk budowania relacji z klientami"
      metaTitle="5 dobrych praktyk budowania relacji z klientami"
      metaDescription="Jak skutecznie budować relacje z klientem? I jak można do tego wykorzystać CRM? ✅ Przeczytaj artykuł!"
    >
      <p style={{
          paddingTop: 20,
          paddingBottom: 15,
        }}>
        Każda firma chce przyciągnąć nowych klientów, ale rynek stał się bardziej wymagający w&nbsp;ostatnich latach. 
        Świadomość klientów znacznie wzrosła, a zaawansowane technologie i&nbsp;lepsze narzędzia oznaczają, że kupujący 
        ma ułatwiony dostęp do informacji i ofert konkurencji. Często przedsiębiorstwo stara się głównie koncentrować 
        na zdobywaniu nowych klientów - przeznacza się na to większość budżetu marketingowego, ale z&nbsp;reguły podczas 
        obsługi klienta zapominamy o&nbsp;działaniach, które sprawią, że pozyskani klienci staną się lojalnymi i trwałymi 
        klientami (marketing relacji). Proces budowania relacji z klientami ma jednak tak cenne znaczenie, że zasługuje 
        na większą uwagę. Zapoznaj się z naszym materiałem i dowiedz się dlaczego warto budować relacje z&nbsp;kontrahentami!
      </p>
      <ResponsiveEmbed
        src="https://www.youtube.com/embed/UnCMKaoS5H0"
        allowFullScreen
        ratio="16:9"/>
      <p style={{
          paddingTop: 20,
        }}>
       A to właśnie stali klienci są najbardziej wartościowi. Harvard Business Review opublikował badania, w których 
       wskazuje się, że&nbsp;powracający klienci chcą wydawać nawet o&nbsp;67% więcej niż nowi. Inne badania wykazały, 
       że próba pozyskania do firmy kolejnego klienta może być dziesięć razy droższa niż utrzymanie już raz pozyskanego.
      </p>

      <p>
        Jaki jest wniosek? Musimy dbać o swoich kontrahentów, a budowanie relacji osobistych z&nbsp;klientami 
        powinno być codziennym zadaniem każdego pracownika działu obsługi klienta.
      </p>
      <h2 style={{
          paddingTop: 25,
          paddingBottom: 15,
        }}>Dlaczego warto budować dobre relacje z klientami?</h2>
      <p>
        Zastanów się, jakie działania może podjąć Twoja firma, aby raz pozyskanego zatrzymać przy sobie. 
        Od razu zaznaczmy, że lojalność klientów nie będzie możliwa bez ustanowienia długoterminowych relacji 
        między obiema stronami. Stosunki ze stałymi klientami powinny być oparte na wzajemnym zaufaniu. Aby 
        osiągnąć tego rodzaju relację, musimy sprawić, by pozyskany klient poczuł się ważny i był zadowolony.
      </p>

      <p>
        Chyba większość z nas spotkała się z&nbsp;powiedzeniem, że zadowolony klient poleca firmę 
        jednej osobie, natomiast jeden niezadowolony odstraszy dziesięciu kolejnych. Dlatego 
        trzeba się dobrze zastanowić, jak w&nbsp;pełni uszczęśliwić kontrahenta naszymi usługami. 
        Jeśli chcesz budować relacje z kontrahentami, które będą trwałe i stabilne, musisz 
        dogłębnie zapoznać się z obszarem potrzeb klientów.
        </p>

      <p>
        Skoro wiemy już, dlaczego tak ważne są dobre relacje z klientami, omówmy teraz sobie, jak je stworzyć. 
        Poniżej przedstawiamy pięć podstawowych zasad budowania relacji z&nbsp;klientami. 
        Dowiedz się, jak budować skuteczne relacje!</p>


        <h2 style={{
          paddingTop: 25,
          paddingBottom: 25,
        }}>Zarządzanie relacjami z&nbsp;klientami – sposoby marketingu relacji</h2>
      <h3 style={{
          paddingBottom: 15,
        }}><strong>1 </strong>– Segmentacja oraz klasyfikacja klientów</h3>
      <p>
        Konstruując własny system budowania relacji warto pamiętać o jednej rzeczy – oferowane przez 
        nas produkty lub usługi nie zaspokoją potrzeb wszystkich konsumentów. Musisz odpowiedzieć na 
        pytanie – kto jest moim klientem i starać się o pozyskanie kontrahentów pasujących do tego 
        profilu. Celem każdej firmy powinna być właściwa klasyfikacja kontrahenta – to znaczy 
        określenie, do jakiej grupy chce kierować swój produkt. Im lepiej oferta spełnia 
        oczekiwania i potrzeby wyodrębnionej grupy, tym bardziej prawdopodobne, że 
        wielu tworzących ją klientów chce zostać lojalnymi odbiorcami.
      </p>

      <p style={{
          paddingBottom: 15,
        }}>
        CRM ułatwia segmentację klientów danej firmy. Możesz utworzyć kryteria opisujące 
        potencjalnego klienta, które mogą później posłużyć do weryfikacji, czy oferowane 
        przez Twoją firmę produkty i usługi mają szansę spełnić jego oczekiwania. Dodatkowo 
        możesz wykorzystać uzyskaną wiedzę do rozwiązywania problemów klientów. 
        Z pewnością wpłynie to pozytywnie na standard budowania relacji osobistych z kontrahentami.
      </p>

      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Pragniesz budować trwałe relacje biznesowe z Twoimi klientami?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować CRM i&nbsp;dbać o&nbsp;klientów z najwyższą jakością
            </Link>
          </div>
          </div>
    </section>
      <h3 style={{
        paddingTop: 25,
          paddingBottom: 15,
        }}><strong>2 </strong>– Obsługa potransakcyjna – rola systemu CRM</h3>
      <p>
        Mówiliśmy wcześniej o badaniach, według których pozyskanie nowego klienta jest o wiele 
        droższe niż dalsza współpraca z klientem obecnym. Jednak żeby miał on chęć na zakup 
        kolejnych produktów lub usług, koniecznie musi być zadowolony z poprzedniej transakcji.
      </p>

      <p style={{
          paddingBottom: 10,
        }}>
        Wiele firm popełnia błąd polegający na tym, że po sprzedaży nie podejmuje już próby 
        kontaktu z&nbsp;poszczególnymi klientami. System CRM może tu&nbsp;pomóc – można w nim łatwo 
        zaplanować działanie, aby porozmawiać z klientem. Wszystko, co musisz zrobić, to ustawić 
        zadanie kontaktu po sprzedaży i za kilka dni sprawdzić, czy klient jest zadowolony z usługi, 
        czy może wystąpiły jakieś wątpliwości? Warto szybko to&nbsp;sprawdzić i nie czekać na to, 
        aż klient nas opuści bez podawania przyczyny. To klucz do&nbsp;dobrego budowania relacji.
      </p>

      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.relacje]}
        alts={["Raport BDM - utrzymanie współpracy z klientami w systemie CRM"]}
      />
      <h3 style={{
        paddingTop: 25,
          paddingBottom: 15,
        }}><strong>3 </strong>– Jak budować skuteczne relacje? Postaw na utrzymanie klienta!</h3>
      <p>
        Jak już mówiliśmy wcześniej, priorytetem każdej firmy, która chce budować zaufanie klientów, 
        powinna być ścisła opieka nad nimi i stałe monitorowanie ich potrzeb.
      </p>

      <p>
       Od czasu do czasu przeprowadzaj audyty danych klientów. CRM również tutaj będzie pomocny, 
       ponieważ możesz zaplanować w nim cykliczne zadanie. Nie dzwoń z propozycją kolejnej sprzedaży 
       tylko zorientuj się, co słychać u klienta. Porozmawiaj o tym, jak działa, czego teraz 
       doświadcza i czego potrzebuje. Zapisz to&nbsp;w&nbsp;systemie CRM i zaplanuj działanie, które pomoże spełnić oczekiwania klienta. 
      </p>

      <h3 style={{
        paddingTop: 25,
          paddingBottom: 15,
        }}><strong>4 </strong>– Budowanie pozytywnych relacji z klientem a rozwój oferty</h3>
      <p>
        Aby nawiązać stabilne stosunków z klientami, musimy zaangażować ich w rozwój naszych 
        produktów oraz usług. CRM może być wsparciem w tym aspekcie budowania relacji, udostępniając 
        takie opcje jak masowy mailing. W&nbsp;wiadomości takiej można na przykład rozesłać po 
        klientach ankietę na temat produktów czy współpracy.
      </p>

      <p>
       Możemy również utworzyć kampanię sprzedażową, której celem będzie wprowadzenie na rynek 
       nowej usługi lub produktu. Możemy włączyć obecnych klientów do tej kampanii i&nbsp;poprosić 
       ich o opinię na temat oferowanych produktów. Następnie warto przyjrzeć się wynikom 
       sprzedaży dla danej kampanii i&nbsp;przeanalizować ewentualne przyczyny utraconych 
       transakcji. Każde z tych działań będzie wspierane przez raporty zawarte w&nbsp;systemie CRM.
      </p>
      <h3 style={{
        paddingTop: 25,
          paddingBottom: 15,
        }}><strong>5 </strong>– Dwukierunkowa komunikacja z&nbsp;klientami w procesie sprzedaży</h3>
      <p style={{
          paddingBottom: 15,
        }}>
        Jeśli chcesz budować relacje z klientem, pamiętaj, że musisz skrupulatnie analizować 
        wszelkie płynące od niego sygnały. Wśród nich można znaleźć wiele inspiracji do tworzenia 
        nowych produktów lub usług. Po każdej rozmowie z obsłużonym klientem sporządzaj w&nbsp;systemie 
        CRM notatki o tym, czego szukają, czego potrzebują i z czym mają problemy. 
        Przeanalizuj te dane, ponieważ mogą one być podstawą do rozwoju Twojej oferty.
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie2]}
        alts={[
          "Raport “Raport: Dynamika satysfakcji - utrzymanie współpracy z klientami",
        ]}
      />
      <p style={{
          paddingTop: 25,
        }}>
        Praktyka budowania relacji polega na tym, że klient współtworzy relację. 
        W związku z tym w&nbsp;marketingu relacji wartość produktu lub usługi nie tworzona nie tyle 
        dla niego, co wspólnie z nim. Wymaga to wypracowania procesów, narzędzi, technik i kierowania 
        pracą handlowców tak, aby stworzyć wartość, której oczekuje klient. System CRM może być 
        tutaj praktycznym narzędziem wsparcia działającym jako standard budowania relacji 
        osobistych z&nbsp;partnerami ze strony klienta.
      </p>

      <h2 style={{
          paddingTop: 25,
          paddingBottom: 25,
        }}>Zbuduj wysoki poziom relacji z klientem – podsumowanie</h2>

        <p>
        Budowanie długotrwałych relacji z kontrahentami to niezwykle istotny obszar. 
        Niemałym wyzwaniem jest pozyskanie wartościowego klienta, a następnie stałe 
        utrzymywanie dobrych relacji z nim. Skutkuje to jednak wieloma pozytywnymi efektami. 
        Wśród głównych korzyści budowania relacji, w sposób w którym klienci traktują firmę 
        jako partnera jest przede wszystkim to, że jeden obsłużony klient, nie tylko pozostanie 
        w relacji dłużej, ale dodatkowo przyprowadzi ze sobą kolejnych. To szansa na poszerzanie 
        grona odbiorców i rozwój formy relacji firma-klient.
      </p>
      <p>
        Już teraz wykorzystaj wsparcie programu CRM i&nbsp;buduj niezastąpione relacje z potencjalnymi klientami firmy!
      </p>

<section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Pragniesz budować trwałe relacje biznesowe z Twoimi klientami?</h3>
        <p style={{
          textAlign: "center",
        }}>Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować CRM i&nbsp;dbać o&nbsp;klientów z najwyższą jakością
            </Link>
          </div>
          </div>
    </section>
      <p style={{
          paddingTop: 25,
        }}>
        Więcej informacji na temat użytkowania systemu CRM znajdziesz między innymi tutaj:
      </p>
      <p>
        <ul>
          <li>
            <Link to="/wdrazanie-kultury-pracy-CRM">
              Wdrażanie kultury pracy z CRM w organizacji
            </Link>
          </li>
          <li>
            <Link to="/zarzadzanie-zespolem-marketingu-crm">
              Zarządzanie pracą zespołu marketingu w&nbsp;systemie CRM
            </Link>
          </li>
          <li>
            <Link to="/zarzadzanie-zespolem-rozproszonym">
              Zarządzanie rozproszonym zespołem sprzedażowym w czasie kryzysu
            </Link>
          </li>
          <li>
            <Link to="/wsparcie-sprzedazy/">
              Wsparcie sprzedaży – jak CRM może pomóc menedżerom sprzedaży?
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default Specrm;
